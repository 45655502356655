import React from 'react'
import { Layout, Col, Row, Image, Typography, Button, Space} from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';

import './features.less'
const { Content } = Layout;
const { Title, Paragraph, Text, Link } = Typography;

export default function FeatureTemplete({title, description, children}) {
  // console.log("FeatureTemplete: ", title, description, children)
  return (
    <Content className='features-root'>
      <Col className='features-container' >
        <Row style={{alignItems: 'center'}}>
          <Typography>
            <Title className="features-title">{title}</Title>
            <Paragraph className='features-subtitle'>
              {description}
            </Paragraph>
          </Typography>
        </Row>
        {children}
      </Col>
    </Content>
  )
}