import React from 'react'
import { Layout, Col, Row, Image, Typography, Button, Space} from 'antd';
import Seo from "../Seo"

import './employee-tracking.less'
const { Content } = Layout;
const { Title, Paragraph, Text, Link } = Typography;

export default function EmployeeTracking({title, description}) {

  // console.log("LocationTracking: props: ", location)

  let location = null
  if (typeof window !== `undefined`){
    location = window.location
  }

  return (
    <div>
      <Seo
        title={title}
        description={description}
        // image={`http:${post.heroImage.resize.src}`}
        type="Product"
        origin={location != null ? location.origin : null}
        pathname={location != null ? location.pathname : null}
      />
      Employee tracking body
    </div>
  )
}
