import React from 'react'
import Layout from "../../components/Layout"
import EmployeeTracking from "../../components/EmployeeTracking"
import FeatureTemplete from "../../components/Features/FeatureTemplete"

export default function index() {
  const title="Employee location tracking"
  const description="Tracking. Attendance. Fullfilment. Expense. Performance. Productivity. Analytics, Security. Integrations. Accessibility. We’ve got it covered for you. FieldNext makes the hardest parts of field operation management simple, leaving you more time focusing on your business."

  return (
    <Layout>
      <FeatureTemplete
        title={title}
        description={description}
      >
        <EmployeeTracking 
          title={title}
          description={description}
        />
      </FeatureTemplete>
    </Layout>
  )
}
